/*! (c) Lavaimagem - https://www.lavaimagem.com/ */

var playing;
var previousVolume = 0.7; // Must be the same as the default volume slider value

/**
 * Redirect
 */
function go(url) {
  window.location = url;
}

/**
 * ID Selector
 */
function ids(id) {
  return document.getElementById(id);
}

/**
 * Close pub box
 */
function closePpm() {
  $('.ppm').hide();
  var now = new Date();
  var now = new Date(now.getTime() + 2 * 60000);
  //now.setDate(now.getDate() + 1);
  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var date = days[now.getDay()] + ', ' + now.getDate() + ' ' + months[now.getMonth()] + ' ' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + ' UTC';
  document.cookie = 'rp_ad=hidden; expires=' + date + '; path=/';
}
/**
 * Close install box
 */

 /**
  * Detect if mobile browser
  * For IOS:              https://itunes.apple.com/appdir/  *
  * For ANDROID:  https://play.google.com/store/apps/ *
  */
if (typeof window.orientation !== 'undefined') {
    $('.iim').show();
    // setTimeout(function () { window.location = "https://itunes.apple.com/appdir"; }, 25);
    // window.location = " myapp:// ";
} else {
    $('.iim').hide();
}
function closeIim() {
  $('.iim').hide();
  var now = new Date();
  var now = new Date(now.getTime() + 2 * 120000);
  //now.setDate(now.getDate() + 1);
  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var date = days[now.getDay()] + ', ' + now.getDate() + ' ' + months[now.getMonth()] + ' ' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + ' UTC';
  document.cookie = 'rp_mobile_install=hidden; expires=' + date + '; path=/';
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}
/**
 * Font size changer
 */
function fontSize(containerId, action) {
  var elFontSize = parseInt($('#' + containerId + ' *').css('font-size'));
  if (action == '-') {
    var newSize = (elFontSize - 2) + 'px';
    if (newSize == '9px') { // Min 9+2=11px
      return;
    }
    $('#' + containerId + ' *').css('font-size', newSize);
  } else {
    var newSize = (elFontSize + 2) + 'px';
    if (newSize == '23px') { // Máx 23-2=21px
      return;
    }
    $('#' + containerId + ' *').css('font-size', newSize);
  }
}

/**
 * Make an XHR request
 */
function getJson(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      var data = JSON.parse(this.responseText);
      callback(data);
    }
  };
  xhr.open('GET', url, true);
  xhr.send();
}

/**
 * Callback function to fill current animator on all pages
 */
function fillCurrentAnimator(data) {
  $('.current-animator').html(data.name);
}

/**
 * Callback function to fill current animator on all pages
 */
function fillCurrentlyPlaying(data) {
  $('.currently-playing').html(data.current);
}

/**
 * Callback function to fill the playing and next song on the full page player
 */
function fillPlaying(data) {
  $('.player-playing span').html(data.current);
  $('.player-next span').html(data.next);
  $('.current-program').length && $('.current-program').html(data.program);
  playing = btoa(data.current); // Used to cache the image if the track doesn't change or force an image refresh otherwise
  $('#albumart').attr('src', '/apiavaplayer.php?_url=/albumimg&amp;cache=' + playing);
  $('.player-media .art').css('background-image', 'url(/apiavaplayer.php?_url=/albumimg&amp;cache=' + playing + ')');
}

/**
 * Callback function to fill the song history on the full page player
 */
function fillHistory(data) {
  var songs = '';
  for (var i = 0; i < data.length; i++) {
    songs += '<li><b>' + data[i].hour + ':' + data[i].minute + '</b> ' + data[i].artist + ' - ' + data[i].song + '</li>';
  }
  $('.player-songs-history').html(songs);
}

/**
 * Checks if audio is playing
 */
function isPlayingRadio() {
  if ($('.player-button').html() == 'pause_circle_outline') {
    return true;
  }

  return false;
}

/**
 * Starts playing the radio
 */
function playRadio() {
  $('.player-button').html('pause_circle_outline');
  document.getElementById('player').play();
  $('.bars').css('background-image', "url('/img/audiowave.svg')");
}

/**
 * Stops playing the radio
 */
function pauseRadio() {
  $('.player-button').html('play_circle_outline');
  document.getElementById('player').pause();
  $('.bars').css('background-image', 'none');
}

/**
 * Toggles radio volume mute
 */
function muteRadio() {
  if (document.getElementById('player').volume > 0) {
    previousVolume = document.getElementById('player').volume;
  }
  if (document.getElementById('player').volume > 0) {
    document.getElementById('player').volume = 0;
    $('.volume-icon').html('volume_off');
  } else {
    document.getElementById('player').volume = previousVolume;
    $('.volume-icon').html('volume_down');
  }
}

/**
 * Open/Close the sidebar
 */
function closeMenu() {
  $('.sidebar-toggle').removeClass('open');
  $('.sidebar').css('right', '-330px');
  $('.logo, .menu-top, .container, footer').css('left', '0');
  $('.sidebar-highlighter').hide();
  $('.sidebar-highlighter').removeClass('visible');
}
function openMenu() {
  $('.sidebar-toggle').addClass('open');
  $('.sidebar').css('right', '0');
  $('.logo, .menu-top, .container, footer').css('left', '-330px');
  $('.sidebar-highlighter').show();
  $('.sidebar-highlighter').addClass('visible');
}

/**
 * Functions that run after loading the page
 */
$(function () {
  /**
   * Sidebar toggler
   */
  $('.sidebar-toggle').click(function () {
    if ($(this).hasClass('open')) {
      closeMenu();
    } else {
      openMenu();
    }
  });
  $(window).click(function () {
    if ($('.sidebar-toggle').hasClass('open')) {
      closeMenu();
    }
  });
  $('.sidebar-toggle, .sidebar').click(function (event) {
    event.stopPropagation();
  });

  /**
   * Get current animator
   */
  $('.current-animator').length && getJson('/apiavaplayer.php?_url=/animator', fillCurrentAnimator);

  /**
   * Get currently playing song
   */
  $('.currently-playing').length && getJson('/apiavaplayer.php?_url=/air', fillCurrentlyPlaying);

  /**
   * Player
   */
  if ($('.player-media').length) {
    getJson('/apiavaplayer.php?_url=/air', fillPlaying);
    getJson('/apiavaplayer.php?_url=/history/60', fillHistory);

    // Autoplay

    playRadio(); // <--- Comment to stop autoplay
    ids('player').volume = previousVolume;

    // Auto refresh

    setInterval(function () {
      getJson('/apiavaplayer.php?_url=/air', fillPlaying);
      getJson('/apiavaplayer.php?_url=/history/60', fillHistory);
      getJson('/apiavaplayer.php?_url=/animator', fillCurrentAnimator);
    }, 10000);
  }

  /**
   * Play media
   */
  $('.audio-play').click(function () {
    if (isPlayingRadio()) {
      pauseRadio();
    } else {
      playRadio();
    }
  });

  /**
   * Volume slider event handler
   */
  $('#volslider').change(function (v) {
    $('.volume-icon').html('volume_down');
    ids('player').volume = v.value.newValue / 100;
  });

  /**
   * Affix the top menu bar
   */
  $(window).on('scroll', function (event) {
    var scrollValue = $(window).scrollTop();
    if (scrollValue > 110) {
      $('.menu-top').addClass('menu-top-fixed');
      $('.logo').addClass('menu-top-fixed-spacer');
    } else {
      $('.menu-top').removeClass('menu-top-fixed');
      $('.logo').removeClass('menu-top-fixed-spacer');
    }
  });

  /**
   * Lightbox
   */
  $('#gallery').length && $('#gallery img').each(function () {
    if (!$(this).parent().hasClass('d-none')) {
      $(this).wrap('<a href="' + $(this).attr('src') + '" class="gallery-item"></a>');
      $('#gallery-brief').append('<a href="' + $(this).attr('src') + '" class="gallery-item"><img src="' + $(this).attr('src') + '" alt=""></a>');
    }
  }) && $('#gallery').lightGallery({
    selector: '.gallery-item'
  }) && $('#gallery-brief').lightGallery({
    selector: '.gallery-item'
  }) && $('.gallery-brief-right').click(function () {
    $('#gallery-brief').scrollTop($('#gallery-brief').scrollTop() + 70);
    var scrollTotal = $('#gallery-brief')[0].scrollHeight;
    if (scrollTotal > 70 && $('#gallery-brief').scrollTop() < scrollTotal - 80) { // 100 para compensar os 10px de padding
      $(this).removeClass('disabled');
      $('.gallery-brief-left').removeClass('disabled');
    } else {
      $(this).addClass('disabled');
      $('.gallery-brief-left').removeClass('disabled');
    }
  }) && $('.gallery-brief-left').click(function () {
    $('#gallery-brief').scrollTop($('#gallery-brief').scrollTop() - 70);
    var scrollTotal = $('#gallery-brief')[0].scrollHeight;
    if (scrollTotal > 70 && $('#gallery-brief').scrollTop() >= 70) {
      $(this).removeClass('disabled');
      $('.gallery-brief-right').removeClass('disabled');
    } else {
      $(this).addClass('disabled');
      $('.gallery-brief-right').removeClass('disabled');
    }
  });
  if ($('#gallery').length && $('#gallery').length > 0) {
    if ($('#gallery-brief').length && $('#gallery-brief').length > 0) {
      var scrollTotal = $('#gallery-brief')[0].scrollHeight;
      if (scrollTotal > 70 && $('#gallery-brief').scrollTop() < scrollTotal - 80) {
        $('.gallery-brief-right').removeClass('disabled');
      }
    }

  }
  $('#videos').length && $("#videos").lightGallery();

  /**
   * Pricing table
   */
  $('input[type=radio]').click(function () {
    var div = $(this).parent().parent();
    if (this.previous) {
      this.checked = false;
    }
    var table = div.parent().parent().parent().parent();
    $('#' + table[0].id + ' .infoinner').removeClass('active');
    this.previous = this.checked;
    if (this.checked) {
      div.addClass('active');
    } else {
      div.removeClass('active');
    }
  });
});

/**
 * Cookie consent
 */
window.addEventListener("load", function () {
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: "#e9ebee",
        text: "#2c3a67"
      },
      button: {
        background: "transparent",
        text: "#2c3a67",
        border: "#2c3a67"
      }
    },
    position: "bottom",
    static: !0,
    content: {
      message: "A Radio Pico utiliza cookies para assegurar uma melhor experiência aos nossos utilizadores!",
      dismiss: "Entendi!",
      link: "Mais Sobre..."
    }
  })
});

/**
 * Share on text select
 */
var sharect = new Sharect();
sharect.config({
  facebook: true,
  twitterUsername: '@RPRadioPico',
  backgroundColor: '#2d3b65'
}).init();

/**
 * OneSignal
 */
useragentid = null;
OneSignal.push(function () {
  OneSignal.getUserId().then(function (userId) {
    if (userId == null) {
      $('.btn-unsubscribe').hide();
    } else {
      useragentid = userId;
      $('.btn-unsubscribe').show();
      OneSignal.push(["getNotificationPermission", function (permission) { }]);
      OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        if (isEnabled) {
          $('.btn-unsubscribe').show();
          $('.btn-subscribe').hide();
        } else {
          $('.btn-unsubscribe').hide();
          $('.btn-subscribe').show();
        }
      });
    }
  });
});
OneSignal.push(function () {
  OneSignal.on('subscriptionChange', function (isSubscribed) {
    if (isSubscribed == true) {
      $('.btn-unsubscribe').show();
      $('.btn-subscribe').hide();
    } else if (isSubscribed == false) {
      OneSignal.getUserId().then(function (userId) {
        useragentid = userId;
      });
      $('.btn-unsubscribe').hide();
      $('.btn-subscribe').show();
    } else {
      console.log('Unable to process the request');
    }
  });
});
$('.btn-subscribe').click(function () {
  if (useragentid != null) {
    OneSignal.setSubscription(true);
  } else {
    OneSignal.registerForPushNotifications({
      modalPrompt: true
    });
  }
  $('.btn-unsubscribe').show();
  $('.btn-subscribe').hide();
});
$('.btn-unsubscribe').click(function () {
  OneSignal.setSubscription(false);
  $('.btn-unsubscribe').hide();
  $('.btn-subscribe').show();
});

$('.button-wpp').click(function () {
    // href="https://web.whatsapp.com/send?text=www.google.com"
    // href="whatsapp://send?text=Link pra um artigo ó https://google.com"
    // location.href = 'www.yoursite.com';

    var url = $(this).data('href');
    if (typeof window.orientation !== 'undefined') {
        location.href = 'whatsapp://send?text=' + url;
    } else {
        location.href = 'https://web.whatsapp.com/send?text=' + url;
    }

});
